import * as React from 'react'
import { Link } from 'gatsby'

const GridItem = ({uri, title, children, centerTitle = false}) => {
  let titleClass = 'text-black text-lg font-black'

  if (centerTitle) {
    titleClass += ' text-center'
  }

  return (
    <Link to={uri} className="flex flex-col border-gray-900 p-6 rounded-3xl border">
      { children }
      <h3 className={ titleClass }>
        {title}
      </h3>
    </Link>
  )
}

export default GridItem