import * as React from 'react'
import {graphql} from 'gatsby'
import Layout from '../layouts/layout'
import Grid from '../components/grid'
import GridItem from '../components/gridItem'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Grid>
        { data.allWpLattecat.nodes.map(node => (
          <GridItem key={node.id} title={node.name} uri={`/products/${node.slug}`} centerTitle={true} />
        ))}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpLattecat(filter: {parentDatabaseId: {eq: null}}) {
      nodes {
        id
        name
        slug
      }
    }
  }
`

export default IndexPage
